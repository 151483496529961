import Axios from 'axios'
import { Metric } from 'web-vitals'

import ErrorBoundaryFallbackComponent from './ErrorBoundaryFallbackComponent'
import ErrorBoundary from './ErrorBoundary'
import getEnv from './getEnv'

interface ErrorObject extends Object {
  info?: string
  isAxiosError?: boolean
  toJSON?: () => object
}

interface LoggingInfo {
  message: string | Event
  url?: string
  lineNumber?: number
  columnNumber?: number
  error?: ErrorObject | string
}

interface LoggingOptions {
  url?: string
  level?: LogLevel
}

type MetricSubset = Pick<Metric, 'name' | 'value'>
interface MetricInfo extends MetricSubset {
  namespace?: string
  url?: string
}

enum LogLevel {
  Info = 'INFO',
  Warn = 'WARN',
  Error = 'ERROR',
}

const scrubData = (loggingInfo: LoggingInfo) => {
  const filterStrings = [
    'access_token',
    'token',
    'id_token',
    '_key',
    'authorization',
    'auth',
  ]

  const message = loggingInfo.message
    ? loggingInfo.message.toString().toLowerCase()
    : ''

  // this property needs to handle Javascript errors from window.onerror and errors caught in the ErrorBoundary
  let errorString = ''

  if (typeof loggingInfo?.error === 'string') {
    // if we have a string, use it
    errorString = loggingInfo.error
  } else if (
    // if we have a generic error we need to dig deeper
    loggingInfo.error instanceof Error
  ) {
    const errorObject = loggingInfo?.error as ErrorObject
    if (errorObject?.isAxiosError && errorObject?.toJSON) {
      // if its an axios error, use its built in method
      errorString = JSON.stringify(errorObject.toJSON())
    } else {
      // else, toString the error
      errorString = loggingInfo.error.toString()
    }
  } else if (typeof loggingInfo.error === 'object') {
    // error is likely a custom object, so stringify it
    errorString = JSON.stringify(loggingInfo.error)
  }

  errorString = errorString.toLowerCase()

  if (loggingInfo.url) {
    let urlObject = new URL(loggingInfo.url)

    // Strip any search or hash parameters
    urlObject.search = ''
    urlObject.hash = ''

    loggingInfo.url = urlObject.href
  } else {
    loggingInfo.url = window.location.href
  }

  filterStrings.forEach((filterStringValue) => {
    if (message.search(filterStringValue) !== -1) {
      loggingInfo.message =
        'Message suppressed due to potentially sensitive information'
    }
    if (errorString.search(filterStringValue) !== -1) {
      loggingInfo.error = {
        info: 'Error suppressed due to potentially sensitive information',
      }
    }
    if (
      loggingInfo.url &&
      loggingInfo.url.toLowerCase().search(filterStringValue) !== -1
    ) {
      loggingInfo.url =
        'URL suppressed due to potentially sensitive information'
    }
  })

  return loggingInfo
}

/**
 * A precanned helper function intended to wire up to `window.onerror`
 * @param message
 * @param url
 * @param lineNumber
 * @param columnNumber
 * @param error
 * @example
 * window.onerror = onError
 */
const onError = (
  message: string | Event,
  url?: string,
  lineNumber?: number,
  columnNumber?: number,
  error?: Object
) => {
  logEvent(
    { message, url, lineNumber, columnNumber, error },
    { level: LogLevel.Error }
  )
}

/**
 * Sends an event to logging endpoint.
 * @param loggingInfo
 * @param loggingOptions
 * @example
 * const MyComponent = (props) => {
 *   useEffect(() => {
 *     const loggingInfo = {
 *       message: "This is an error",
 *       url: "http://localhost/testurl",
 *       lineNumber: 10,
 *       columnNumber: 10,
 *       error: {}
 *     }
 *     const optionalLoggingOptions = {
 *        url: 'http://differentlogging/service',
 *        level: LogLevel.Error
 *     }
 *     logEvent(loggingInfo, optionalLoggingOptions)
 *   }, [ props.trigger ])
 *   return <div>Hello World</div>
 * }
 */
const logEvent = (
  loggingInfo: LoggingInfo,
  loggingOptions: LoggingOptions = {}
) => {
  if (!loggingInfo.message) {
    console.error('Praxis Logging Error: Message is required')
    return
  }

  if (!loggingOptions.url) {
    loggingOptions.url = '/log_message'
  }
  if (!loggingOptions.level) {
    loggingOptions.level = LogLevel.Info
  }

  try {
    const scrubbedInfo = scrubData(loggingInfo)

    if (process.env.NODE_ENV === 'development') {
      console.log(
        `[${process.env.NODE_ENV}][${loggingOptions.level}][Praxis] - `,
        scrubbedInfo
      )
    } else {
      Axios.post(loggingOptions.url, scrubbedInfo, {
        headers: { loglevel: loggingOptions.level },
      })
    }
  } catch (e) {
    console.error(`Praxis Logging Error:`, e)
  }
}

/**
 * Sends an event to metrics endpoint.
 * @param metricInfo
 * @example
 *     const metricInfo = {
 *       namespace: "webvitals",
 *       url: "/metric",
 *       name: "ttfb",
 *       value: 3.25,
 *     }
 *     logMetric(metricInfo)
 */
const logMetric = (metricInfo: MetricInfo) => {
  if (!metricInfo.name || !metricInfo.value) {
    console.error('Praxis Metric Error: name and value are required')
    return
  }

  if (!metricInfo.namespace) {
    metricInfo.namespace = 'webvitals'
  }
  if (!metricInfo.url) {
    metricInfo.url = '/metric'
  }

  try {
    const blob = new Blob(
      [
        `${
          metricInfo.namespace
        }.${metricInfo.name.toLowerCase()},env=${getEnv()} ${metricInfo.name.toLowerCase()}=${
          metricInfo.value
        }`,
      ],
      {
        type: 'text/plain',
      }
    )
    Axios.post(metricInfo.url, blob, {
      headers: {
        'content-type': 'text/plain',
      },
    })
  } catch (e) {
    console.error(`Praxis Metric Error:`, e)
  }
}

const reportHandler = (metric: Metric) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(
      `[${process.env.NODE_ENV}][Metric][Praxis] - `,
      `${metric.name.toLowerCase()} : ${metric.value}`
    )
  } else {
    logMetric({ name: metric.name, value: metric.value })
  }
}

const reportWebVitals = () => {
  import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
    getCLS(reportHandler, false)
    getFID(reportHandler)
    getFCP(reportHandler)
    getLCP(reportHandler)
    getTTFB(reportHandler)
  })
}

export {
  logEvent,
  logMetric,
  reportWebVitals,
  onError,
  ErrorBoundary,
  ErrorBoundaryFallbackComponent,
  LogLevel,
}
