import React, { Component, ComponentType } from 'react'

import ErrorBoundaryFallbackComponent from './ErrorBoundaryFallbackComponent'
import debounce from 'lodash/debounce'
import { onError } from './index'

interface Props {
  children?: any
  FallbackComponent: ComponentType<any>
}

interface ErrorInfo {
  componentStack: string
}

interface State {
  error: Error | null
  info: ErrorInfo | null
}

class ErrorBoundary extends Component<Props, State> {
  static defaultProps = {
    FallbackComponent: ErrorBoundaryFallbackComponent,
  }

  state = {
    error: null,
    info: null,
  }

  logger = debounce((error: Error, info: ErrorInfo) => {
    onError(error.message, window.location.href, undefined, undefined, {
      info: info.componentStack.replace('\n', ''),
    })
  }, 100)

  componentDidCatch(error: Error, info: ErrorInfo): void {
    this.logger(error, info)
    this.setState({ error, info })
  }

  render() {
    const { children, FallbackComponent } = this.props
    const { error, info = {} as ErrorInfo } = this.state

    if (error !== null) {
      return <FallbackComponent error={error} info={info} />
    }

    return children || null
  }
}

export default ErrorBoundary
