import { merge } from 'lodash'
import { useState, useEffect } from 'react'
import { bool, func, node, object, oneOfType, string } from 'prop-types'

import EnvContext from './context'
import getConfig from './configGenerator'
import { CONFIG_PATH } from './constants'
import Loading from './Loading'

const EnvProvider = ({
  children,
  commonConfig,
  configPath,
  loadingIndicator,
  onConfigFailure,
}) => {
  const [config, setConfig] = useState(undefined)

  useEffect(() => {
    getConfig(configPath)
      .then((res) => {
        setConfig(res)
      })
      .catch(onConfigFailure)
  }, [commonConfig, configPath, loadingIndicator, onConfigFailure])

  const mergedConfig = merge(commonConfig, config)

  return (
    <EnvContext.Provider value={mergedConfig}>
      {!config ? (
        loadingIndicator ? (
          <Loading />
        ) : (
          ''
        )
      ) : typeof children === 'function' ? (
        children({ env: mergedConfig })
      ) : (
        children
      )}
    </EnvContext.Provider>
  )
}

EnvProvider.propTypes = {
  configPath: string,
  commonConfig: object,
  loadingIndicator: oneOfType([bool, node]),
  onConfigFailure: func,
}

EnvProvider.defaultProps = {
  configPath: CONFIG_PATH,
  commonConfig: {},
  loadingIndicator: Loading,
  onConfigFailure: () => {},
}

export default EnvProvider
