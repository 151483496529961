import { memo } from 'react'

import Logo from './Praxis.svg'

const Loading = memo(() => {
  return (
    <Logo
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        height: '48px',
      }}
    />
  )
})

export default Loading
